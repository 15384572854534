import frame from "../../img/heroes/frame.png"
import frameGold from "../../img/heroes/frame-gold.png"
import { Link } from 'react-router-dom'

function Card({hero, isGrid, isGold}) {
  return (
    <Link 
      to={`/stars/${hero.id}`}
      className={isGrid ? 'heroes-card heroes-card--grid' : 'heroes-card'}
    >
      <img src={isGold ? frameGold : frame} alt="" className="heroes-card__frame" />
      <div className={isGrid ? 'heroes-card__person heroes-card__person--grid' : 'heroes-card__person'}>
        <img src={hero.photo} alt="" />
      </div>
      <div className={isGrid ? 'heroes-card__text heroes-card__text--grid' : 'heroes-card__text'}>
        <div className={isGrid ? 'heroes-card__name heroes-card__name--grid' : 'heroes-card__name'}>
          {hero.surname}
        </div>
        <div className={isGrid ? 'heroes-card__surname heroes-card__surname--grid' : 'heroes-card__surname'}>
          {hero.name}
        </div>
      </div>
      
    </Link>
  )
}

export default Card;
