function Arrow() {
  return (
    <svg viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_86_1348)">
      <path d="M15 2.82843C15 1.04662 12.8457 0.154284 11.5858 1.41421L1.41421 11.5858C0.633165 12.3668 0.633165 13.6332 1.41421 14.4142L11.5858 24.5858C12.8457 25.8457 15 24.9534 15 23.1716L15 2.82843Z" fill="white" fillOpacity="0.6"/>
      <path d="M11.9393 1.76777C12.8843 0.822819 14.5 1.49207 14.5 2.82843L14.5 23.1716C14.5 24.5079 12.8843 25.1772 11.9393 24.2322L1.76777 14.0607C1.18198 13.4749 1.18198 12.5251 1.76777 11.9393L11.9393 1.76777Z" stroke="url(#paint0_linear_86_1348)" strokeOpacity="0.3"/>
      </g>
      <defs>
      <filter id="filter0_b_86_1348" x="-29.1716" y="-29.1755" width="74.1716" height="84.3511" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feGaussianBlur in="BackgroundImageFix" stdDeviation="15"/>
      <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_86_1348"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_86_1348" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_86_1348" x1="15" y1="-1.94506" x2="11.9899" y2="29.2132" gradientUnits="userSpaceOnUse">
      <stop stopColor="white"/>
      <stop offset="1" stopColor="white" stopOpacity="0"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default Arrow;
