import React, {useState, useEffect, useMemo, useRef} from "react";
import Cards from '../сomponents/heroes/Cards'
import Empty from "../сomponents/heroes/Empty"
import Header from '../сomponents/header/Header'
import Views from '../сomponents/header/Views'
import Filter from '../сomponents/header/Filter'
import Nav from '../сomponents/header/Nav'
import Preloader from '../сomponents/preloader/Preloader'
import useFetching from "../hooks/useFetching"
import API from '../API/API'

function Heroes() {
  const [heroes, setHeroes] = useState([])
  const [view, setView] = useState('row')
  const [champNumber, setChampNumber] = useState(1)

  const filteredHeroes = useMemo(() => {
    return heroes.filter(hero => hero.champNumber === champNumber)
  }, [heroes, champNumber])

  const isGold = useMemo(() => {
    return champNumber >= 6
  }, [champNumber])

  const [getHeroes, isLoading] = useFetching(API.getHeroes, setHeroes)

  const swiperRef = useRef(null)

  const changeChampNumber = number => {
    setChampNumber(number)

    const swiper = swiperRef.current?.swiper

    if (swiper) {
      swiper.slideTo(0, 0)
    }
    
    sessionStorage.setItem('champNumber', number);
  }

  const changeView = view => {
    setView(view)
    sessionStorage.setItem('view', view);
  }

  useEffect(() => {
    const SSView = sessionStorage.getItem('view')

    if (SSView) {
      setView(SSView)
    }

    const SSChampNumber = sessionStorage.getItem('champNumber')

    if (SSChampNumber) {
      setChampNumber(Number(SSChampNumber))
    }

    getHeroes()
  }, [])

  return (
    <div className={isGold ? 'page page--gold' : 'page'}>
      {isGold ? <div className="page__sparks"></div> : ''}
      <Header isGold={isGold}>
        <Nav />
        <Views 
          view={view}
          changeView={changeView}
          isLoading={isLoading}
        />
        <Filter 
          champNumber={champNumber}
          changeChampNumber={changeChampNumber}
          isGold={isGold}
          isLoading={isLoading}
        />
      </Header>
      <div className="page__inner">
        {isLoading ? <Preloader /> :  
          <div className="heroes">
            {filteredHeroes.length ? 
              <Cards
                heroes={filteredHeroes}
                view={view}
                isGold={isGold}
                key={view}
                ref={swiperRef}
              /> :
              <Empty champNumber={champNumber} />
            }
          </div>
        }
      </div>
    </div>
  )
}

export default Heroes;
